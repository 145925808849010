@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0c1017;
  color: whitesmoke;
}
canvas {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

.our-service .slick-slide{
  margin: 0 5px;
}

.card {
  position: relative;
}

.card::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  transition: 0.6s;
  z-index: 0;
  background-color: rgb(22 163 74);
}

.card:hover {
  box-shadow: 0.063rem 0.063rem 1.25rem 0.375rem rgb(0 0 0 / 53%);
}

.card:nth-child(1)::before {
  bottom: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 100%);
}

.card:nth-child(2)::before {
  bottom: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 100%);
}

.card:nth-child(3)::before {
  top: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 0%);
}

.card:nth-child(4)::before {
  top: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 0%);
}

.card p {
  transition: 0.8s;
}

.card:hover::before {
  clip-path: circle(110vw at 100% 100%);
}

.card:hover p {
  color: #fff;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* .card:nth-child(1) .circle {
  background: url("https://images.unsplash.com/photo-1587440871875-191322ee64b0?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
    no-repeat 50% 50% / cover;
  bottom: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 100%);
}

.card:nth-child(2) .circle {
  background: url("https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
    no-repeat 50% 50% / cover;
  bottom: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 100%);
}

.card:nth-child(3) .circle {
  background: url("https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
    no-repeat 50% 50% / cover;
  top: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 0%);
}

.card:nth-child(4) .circle {
  background: url("https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
    no-repeat 50% 50% / cover;
  top: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 0%);
} */
